<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../assets/js/language'
import AboutBanner from './components/about-banner'
import Homefive from './components/home-five'
// 托管现状和规模
import HomeSix from './components/home-six'
// 托管中心展示
import HomeSeven from './components/home-seven'
// 托管中心优势
import HomeEight from './components/home-eight'
const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
// console.log(langvalue.value.Bus_introduction.one, '测试')

const checkedtype = ref(langvalue.value.Bus_introduction.one)
const type = ref(langtype)
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).Bus_introduction.one
    type.value = newVal
  },
  { immediate: true }
)
</script>

<template>
  <div class="c_main">
    <AboutBanner :checkedtype="checkedtype" title="CARE CENTER" />
    <Homefive />
    <HomeSix />
    <HomeSeven :showMore="false" />
    <HomeEight />
  </div>
</template>

<style scoped lang="less">
.c_main {
  
}
</style>
