<template>
  <div class="home-elght">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top_youshi.five }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="itemlist">
        <div class="item">
          <div class="ce_bo">
            <img src="@/assets/images/ce.png" alt="">
          </div>
          <div class="border">
            <div class="img_box">
              <img class="img_top" src="@/assets/images/dl_2.png" />
            </div>

            <div class="value">
              {{
                langvalue.section5.one.substr(
                  0,
                  langvalue.section5.one.indexOf('：')
                )
              }}
            </div>
          </div>
          <div class="itemtext">
            {{
              langvalue.section5.one.substr(
                langvalue.section5.one.indexOf('：') + 1,
                langvalue.section5.one.length
              )
            }}
          </div>
        </div>
        <div class="item">
          <div class="ce_bo">
            <img src="@/assets/images/ce.png" alt="">
          </div>
          <div class="border">
            <div class="img_box">
              <img class="img_top" src="@/assets/images/dl_3.png" />
            </div>
            <div class="value">
              {{
                langvalue.section5.two.substr(
                  0,
                  langvalue.section5.two.indexOf('：')
                )
              }}
            </div>
          </div>
          <div class="itemtext">
            {{
              langvalue.section5.two.substr(
                langvalue.section5.two.indexOf('：') + 1,
                langvalue.section5.two.length
              )
            }}
          </div>
        </div>
        <div class="item">
          <div class="ce_bo">
            <img src="@/assets/images/ce.png" alt="">
          </div>
          <div class="border">
            <div class="img_box">
              <img class="img_top" src="@/assets/images/dl.png" />
            </div>
            <div class="value">
              {{
                langvalue.section5.three.substr(
                  0,
                  langvalue.section5.three.indexOf('：')
                )
              }}
            </div>
          </div>
          <div class="itemtext">
            {{
              langvalue.section5.three.substr(
                langvalue.section5.three.indexOf('：') + 1,
                langvalue.section5.three.length
              )
            }}
          </div>
        </div>
        <div class="item">
          <div class="ce_bo">
            <img src="@/assets/images/ce.png" alt="">
          </div>
          <div class="border">
            <div class="img_box">
              <img class="img_top" src="@/assets/images/dl_1.png" />
            </div>
            <div class="value">
              {{
                langvalue.section5.four.substr(
                  0,
                  langvalue.section5.four.indexOf('：')
                )
              }}
            </div>
          </div>
          <div class="itemtext">
            {{
              langvalue.section5.four.substr(
                langvalue.section5.four.indexOf('：') + 1,
                langvalue.section5.four.length
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))

    const playvideo = () => {
      document.getElementById('video').play()
    }
    const jiankongvideo = () => {
      document.getElementById('video').play()
    }
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
      },
      { immediate: true }
    )
    return { langvalue, playvideo, jiankongvideo, type }
  }
}
</script>
<style scoped lang="less">
.home-elght {
  width: 100%;
  margin: 0 auto;
  padding: 70px 100px 100px;
  background: #f8f8f8;
}
.title {
  font-size: 28px;
  font-weight: bold;
  color: #100e34;
  font-family: 'PingFang SC';
  font-size: 40px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
}
.stitle {
  color: #ffffff50;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
}
.detail {
  width: 1240px;
  font-size: 20px;
  font-weight: 300;
  margin: 80px auto 0;
  // padding: 20px 0;
  .itemlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      background: #fff;
      width: 600px;
      padding: 52px 0;
      margin-bottom: 30px;
      border-radius: 13px;
      position: relative;
      .ce_bo {
        position: absolute;
        left: 0;
        top: 26px;
      }
      .border {
        width: 100%;
        .img_box {
          margin: 0 auto;
          padding: 10px;
          width: 80px;
          height: 80px;
          .img_top {
            width: 60px;
            height: 60px;
          }
        }

        .value {
          text-align: center;
          margin: 9px auto 0;
          line-height: 72px;
          color: #100E34;
          font-size: 40px;
          font-weight: 600;
        }
      }
      .itemtext {
        margin: 10px auto 0;
        width: 500px;
        color: #666872;
        font-size: 30px;
        font-weight: 400;
        // padding: 20px 0;
        line-height: 45px;
      }
    }
  }
}
</style>
