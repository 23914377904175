<template>
  <div class="home-six">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top_1.two }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="di">
        <div class="ditem">
          <div class="box_img"><img src="@/assets/images/tg_pic_1.png" /></div>
          <div class="_box">
            <div class="stitle">{{ langvalue.section4_2.one }}</div>
            <div class="svalue">{{ langvalue.section4_2.two }}</div>
            <div class="svalue">{{ langvalue.section4_2.three }}</div>
          </div>
        </div>
      </div>
      <div class="di">
        <div class="ditem">
          <div class="_box">
            <div class="stitle" style="text-align: right;">
              {{ langvalue.section4_2.four }}
            </div>
            <div class="svalue">
              {{ langvalue.section4_2.five }}
              <br />
              {{ langvalue.section4_2.five_1 }}
            </div>
          </div>
          <div class="box_img"><img src="@/assets/images/tg_pic_2.png" /></div>
        </div>
      </div>
      <div class="di">
        <div class="ditem">
          <div class="box_img"><img src="@/assets/images/tg_pic_3.png" /></div>
          <div class="_box">
            <div class="stitle">{{ langvalue.section4_2.six }}</div>
            <div class="svalue">{{ langvalue.section4_2.seven }}</div>
          </div>
        </div>
      </div>
      <div class="di" style="width: 100%">
        <div class="item_txt">
          <div class="stitle">{{ langvalue.section4_2.eight }}</div>
          <div class="svalue">{{ langvalue.section4_2.nine }}</div>
          <div class="svalue">{{ langvalue.section4_2.ten }}</div>
          <div class="svalue">{{ langvalue.section4_2.eleven }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
        type.value = newVal
      },
      { immediate: true }
    )
    return { langvalue, type }
  }
}
</script>
<style scoped lang="less">
.home-six {
  padding: 126px 100px;
  margin: 0 auto;
  background: #fffffe;
}
.title {
  font-size: 40px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }
  .text {
    margin: 0 10px;
  }
  // color: white;
}
.detail {
  width: 1340px;
  font-size: 20px;
  font-weight: 300;
  margin: 100px auto 0;
  .di {
    width: 100%;
    background: #fffffe;
  }
  .ditem {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_img {
      width: 760px;
      img {
        width: 760px;
        height: 383px;
        border-radius: 30px;
        object-fit: cover;
      }
    }

    ._box {
      width: 480px;
      .stitle {
        font-size: 32px;
        font-weight: 400;
        color: #100e34;
      }
      .svalue {
        margin-top: 48px;
        color: #666872;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
  .item_txt {
    width: 1000px;
    margin: 0 auto;
    .stitle {
      font-size: 32px;
      font-weight: 400;
      color: #100e34;
    }
    .svalue {
      margin-top: 48px;
      color: #666872;
      font-size: 20px;
      font-weight: 400;
    }
  }
}
</style>
